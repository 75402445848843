<template>
  <div
    class="surplus"
    v-loading.fullscreen.lock="loadingTbs"
    element-loading-text="加载中..."
  >
    <div
      class="simpleInfoPanel"
      v-if="hasPermission"
    >
      <el-table
        :data="tableData"
        class="tbsWrap"
        highlight-current-row
      >
        <el-table-column
          prop="serviceType"
          label="服务类型"
          width="230"
        >
        </el-table-column>
        <el-table-column
          prop="serviceContent"
          label="服务内容"
          width=""
        >
        </el-table-column>
        <el-table-column
          prop="currentSurplus"
          label="当前剩余权益量"
          width="230"
        >
        </el-table-column>
        <el-table-column
          prop="count"
          label="权益总数"
          width="230"
        >
        </el-table-column>
      </el-table>
    </div>
    <div class="simpleInfoPanel">
      <div
        class="jurisdiction-wrap"
        v-if="!hasPermission"
      >
        <img
          src="~@/assets/images/Jurisdiction.png"
          alt=""
        >
        <p>暂无权限访问此页，如需开通权限，</p>
        <p>请联系管理员开通</p>
      </div>
    </div>
  </div>
</template>

<script>
import api from '@/api'
import { mapGetters } from 'vuex'
export default {
  name: 'surplus',
  components: {},
  data () {
    return {
      tableData: [],
      hasPermission: true,
      loadingTbs: false
    }
  },
  computed: {
    ...mapGetters({
      user: 'user'
    })
  },
  mounted () {
    const permissionArr = [92]
    const userAuthorities = this.user.confIds.filter(item => {
      return permissionArr.includes(item.id)
    })
    this.getAuthorities(userAuthorities)
  },
  watch: {
    user (newVal, oldVal) {
    }
  },
  methods: {
    getAuthorities (val) {
      if (val && val.length) {
        this.hasPermission = Boolean(val[0].func)
        this.getEditionFunc()
      } else {
        this.hasPermission = false
      }
    },
    getEditionFunc () {
      this.loadingTbs = true
      this.axios.post(api.getEditionInterests, { id: this.user.userId })
        .then((res) => {
          if (res.data.code === 0 && res.data.data) {
            const data = res.data.data
            this.tableData = [{
              id: 0,
              check: 1,
              serviceType: '子账号开通数',
              serviceContent: '子账号授权管理开通数量',
              currentSurplus: data.membernum + '个',
              count: data.totalmembernum + '个'
            }, {
              id: 2,
              check: 1,
              serviceType: '品牌查看权益数',
              serviceContent: '查看品牌“开关店分布+数据分析+拓展需求+联系方式”数据的品牌数',
              currentSurplus: data.lookbrandrightnum + '个',
              count: data.totallookbrandrightnum + '个'
            }, {
              id: 3,
              check: 1,
              serviceType: '品牌联系特权数',
              serviceContent: '发起品牌联系特权数量',
              currentSurplus: data.look4link + '个',
              count: data.totallook4link + '个'
            },
            {
              id: 4,
              check: 1,
              serviceType: '项目查看权益数',
              serviceContent: '查看项目“已签约品牌”数据的项目数',
              currentSurplus: data.signednum + '个',
              count: data.totalsignednum + '个'
            },
            {
              id: 5,
              check: 1,
              serviceType: '项目对标权益数',
              serviceContent: '查看“店铺预警分析-项目对比”的项目数',
              currentSurplus: data.projectCompartnum + '个',
              count: data.totalprojectCompartnum + '个'
            }]
            if (this.user.isadmin === 1 || !data.totalmembernum) {
              this.tableData.shift()
            }
          }
          this.loadingTbs = false
        })
    }
  }
}
</script>

<style scoped lang="stylus"></style>
